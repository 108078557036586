/* body {
    font-family: 'S-Core-CoreSansA45Regular', Arial, Helvetica, sans-serif;
} */
.body-wrap.boxed-container {
    /* width: 85%; */
    width: 100%;
    /* border: 1px solid #6666663d; */
}

.boxed-container {
  /* max-width: fit-content !important; */
}
.list-reset {
    display: flex;
    justify-content: flex-end;
    margin: 1em;
}
.list-reset li {
    color: #223140;
    margin: 0 1em;
    font-weight: 100;
    /* display: flex;
    flex-direction: column; */
}
.list-reset li a {
    text-decoration: none;
    font-size: 18px;
}
.list-reset li.border-menu, .border-menu {
    color: #B41E1C;
    border: 1.9px solid#B41E1C;
    border-radius: 4px;
    font-weight: bold;
    font-family: S-Core-CoreSansA35Light;
    letter-spacing: 0px;
    text-decoration: none;
    padding: 8px 24px;
    
}
.list-reset li.border-menu a, .border-menu a {
    padding: 1em;
}
.list-reset li a.is-active {
    color: #0178EF;
    display: flex;
    flex-direction: column;
}
.logo-image {
    height: 60%;
    margin-left: 1em;
}
.list-reset li .link-dot-bottom {
    /* width: 10px; */
    width: 40px;
    height: 3px;
    background: #0178EF;
    /* margin: auto; */
    /* border-radius: 50%; */
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}

.product-submenus li a {
    text-decoration: none;
}

.container {
  /* max-width: 1220px; */
  /* max-width: 1200px; */
  /* max-width: 100%; */
  padding: 0;
}

@media (max-width: 599.95px) {
    .container {
      padding: 0 24px;
    }
    
}

.header-container {
  height: 120px;
}

/* Coverflow Image slide home page start */

.ui-coverflow-wrapper{
    height:400px;
    margin-top: 100px;
}
.ui-state-active{
    border:0px;
}
/* Coverflow Image slide home page end */

.product-content-border-top {
    width: 24%;
    /* background: #0278EF; */
    height: 5px;
    margin-left: 1em;
}

.product-tab-container {
    transition: width 2s, height 4s;
}

.abt-link-active {
    color: #000 !important;
    /* border-bottom: 5px solid #2196f3 !important; */
    padding-bottom: 10px;
}

.scroll-up-button {
    display: none !important;
    height: 50px;
    width: 50px;
    position: fixed !important;
    bottom: 5%;
    right: 2%;
    border-radius: 0;
    border: 3px solid #0165C9 !important;
    /* background: #0165C9 !important; */
    color: #0165C9 !important;
}
.scroll-up-button:hover {
    height: 50px;
    width: 50px;
    position: fixed !important;
    bottom: 5%;
    right: 2%;
    border-radius: 50%;
    border: 3px solid #0165C9 !important;
    background: #0165C9 !important;
    color: #fff !important;
}
.scroll-up-active {
    display: block !important;
}

.scroll-up-button .scroll-up-icon {
    position: relative;
    bottom: 5px;
}

@media (max-width: 599.95px) {
    .scroll-up-button {
        display: none !important;
    }
    
}

.my-flipster li img {
    width: auto;
    height: 400px;
}
.my-flipster .slick-arrow {
    display: none !important;
}

/* .about-section-footer {
    height: auto;
    min-height: 300px;
    background: transparent url('https://rediflywebhosting.z16.web.core.windows.net/static/media/AdobeStock_210250326.1efbba1b.jpeg') 0% 100% no-repeat padding-box;
    background-size: 100% 100%;
    background-size: cover
} */

/* .about-section-footer::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.25);
} */

.product-tabs-indicator {
    width: 50px !important;
    background: #0178EF !important;
}
.cool-link {
    display: inline-block;
    color: #666666c2 !important;
    text-decoration: none;
    padding: 6px 0px !important;
}

.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: #0178EF;
    transition: width .3s;
    bottom: 0;
    position: absolute;
    /* left: 40%; */
}

.cool-link:hover::after {
    width: 30%;
    /* position: relative; */
    left: 0;
    transition: width .3s;
    
}

.textfield-block .signin-textfield > div:first-child {
    height: 40px;
}

.team-member-profile {
    cursor: pointer;
}
.team-member-profile .team-member-profile-name {
    font-size: 32px; /* font-size: 35px; */
    color: #001212;
}

.team-member-profile .team-member-profile-role {
    font-size: 23px; /* font-size: 25px; */
}

.team-member-profile p.team-member-profile-desc {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear;
}

.team-member-profile:hover .team-member-profile-name {
    color: #0278ef;
}

.team-member-profile:hover .team-member-profile-desc {
    opacity: 1;
    visibility: visible;
    /* transition: opacity 0.3s, visibility 0.5s; */
}

@media (max-width: 599.95px) {
    .team-member-profile .team-member-profile-name {
        font-size: 1.5rem;
        color: #0278ef;
    }
    .team-member-profile .team-member-profile-role {
        font-size: 1.2rem;
    }
    .team-member-profile p.team-member-profile-desc {
        font-size: 1rem;
        opacity: 1;
        visibility: visible;
    }
}

.sales-image-icon {
    width: 75px !important;
    height: 75px !important;
}

@media (max-width: 599.95px) {
    .sales-image-icon {
        width: 60px !important;
        height: 60px !important;
    }
}

.line {
    display: none;
}

@media (min-width: 800px) {
    .line {
            margin-bottom: 0.2rem;
            height: 1px;
            width: 1000px;
            background-color: lightgrey;
            border-radius: 7%;
            display: inline-block;
        }
}
@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.logos {
    overflow: hidden;
    padding: 0 0 0 0;
    background-color: #fefbfb;
    white-space: nowrap;
    position: relative;
}

.logos::before, .logos::after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos::before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos::after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logos-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
}

.logos-slide img {
    height: 200px;
    margin: 0 40px 0 0;
}

@media (width: 820px) {
    .logos-slide img {
            height: 120px;
            margin: 0 20px 0 0;
        }
}

@media (width: 390px) {
    .logos-slide img {
        height: 50px;
        margin: 0 30px 0 0;
    }
}

/* Integration Carousal */
.int-logos {
    overflow: hidden;
    padding: 0 0 0 0;
    background-color: #fefbfb;
    white-space: nowrap;
    position: relative;
}

.int-logos::before,
.int-logos::after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.int-logos::before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.int-logos::after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.int-logos:hover .int-logos-slide {
    animation-play-state: paused;
}

.int-logos-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
}

.int-logos-slide img {
    height: 200px;
    margin: 0 40px 0 0;
}

@media (width: 820px) {
    .int-logos-slide img {
        height: 120px;
        margin: 0 20px 0 0;
    }
}

@media (width: 390px) {
    .int-logos-slide img {
        height: 50px;
        margin: 0 30px 0 0;
    }
}

/* Customer Reveiew */
@media (max-width: 500px) {
    .bubble {
        position: relative;
        background: #ffffff;
        color: #FFFFFF;
        font-family: Arial;
        font-size: 20px;
        line-height: 120px;
        text-align: center;
        width: 350px;
        height: 470px;
        border-radius: 30px;
        padding: 0px;
        box-shadow: 5px 5px 19px 0px rgba(50, 50, 50, 0.2),
    }

    .bubble:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: transparent #ffffff;
        border-width: 15px 0 15px 16px;
        top: 41%;
        bottom: -16px;
        margin-top: -15px;
        box-shadow: 5px 5px 19px 0px rgba(50, 50, 50, 0.2),
    }
}

@media (min-width: 768px) {
    .bubble {
        position: relative;
        background: #ffffff;
        color: #FFFFFF;
        font-family: Arial;
        font-size: 20px;
        line-height: 120px;
        text-align: center;
        width: 540px;
        height: 250px;
        border-radius: 30px;
        padding: 0px;
        box-shadow: 5px 5px 19px 0px rgba(50, 50, 50, 0.2),
    }

    .bubble:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: transparent #ffffff;
        border-width: 15px 0 15px 16px;
        top: 41%;
        right: -16px;
        margin-top: -15px;
        box-shadow: 5px 5px 19px 0px rgba(50, 50, 50, 0.2),
    }
}

@media (min-width: 820px) {
    .bubble {
        position: relative;
        background: #ffffff;
        color: #FFFFFF;
        font-family: Arial;
        font-size: 20px;
        line-height: 120px;
        text-align: center;
        width: 580px;
        height: 250px;
        border-radius: 30px;
        padding: 0px;
        box-shadow: 5px 5px 19px 0px rgba(50, 50, 50, 0.2),
    }

    .bubble:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: transparent #ffffff;
        border-width: 15px 0 15px 16px;
        top: 41%;
        right: -16px;
        margin-top: -15px;
        box-shadow: 5px 5px 19px 0px rgba(50, 50, 50, 0.2),
    }
}

@media (min-width: 1200px) {
    .bubble {
        position: relative;
        background: #ffffff;
        color: #FFFFFF;
        font-family: Arial;
        font-size: 20px;
        line-height: 120px;
        text-align: center;
        width: 600px;
        height: 250px;
        border-radius: 30px;
        padding: 0px;
        box-shadow: 5px 5px 19px 0px rgba(50, 50, 50, 0.2),
    }

    .bubble:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: transparent #ffffff;
        border-width: 15px 0 15px 16px;
        top: 41%;
        right: -16px;
        margin-top: -15px;
        box-shadow: 5px 5px 19px 0px rgba(50, 50, 50, 0.2),
    }
}

.customer-image-bubble {
    position: relative;
    width: 150px;
    height: 150px;
    object-fit: contain;
    overflow: hidden;
    background-color: rgb(235, 245, 245);
    border-radius: 60% 40% 40% 20%;
}

.tileWrapper {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(auto-fit, minmax(0rem, 18rem));
    gap: 3.5rem;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 4rem;
}