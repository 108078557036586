@font-face {
    font-family: S-Core-CoreSansA15Thin-Italic;
    src: url(../fonts-new/S-Core\ -\ CoreSansA15Thin-Italic.otf);
}

@font-face {
    font-family: S-Core-CoreSansA15Thin;
    src: url(../fonts-new/S-Core\ -\ CoreSansA15Thin.otf);
}

@font-face {
    font-family: S-Core-CoreSansA25ExtraLight-Italic;
    src: url(../fonts-new/S-Core\ -\ CoreSansA25ExtraLight-Italic.otf);
}

@font-face {
    font-family: S-Core-CoreSansA25ExtraLight;
    src: url(../fonts-new/S-Core\ -\ CoreSansA25ExtraLight.otf);
}

@font-face {
    font-family: S-Core-CoreSansA35Light-Italic;
    src: url(../fonts-new/S-Core\ -\ CoreSansA35Light-Italic.otf);
}

@font-face {
    font-family: S-Core-CoreSansA35Light;
    src: url(../fonts-new/S-Core\ -\ CoreSansA35Light.otf);
}

@font-face {
    font-family: S-Core-CoreSansA45Regular-Italic;
    src: url(../fonts-new/S-Core\ -\ CoreSansA45Regular-Italic.otf);
}

@font-face {
    font-family: S-Core-CoreSansA45Regular;
    src: url(../fonts-new/S-Core\ -\ CoreSansA45Regular.otf);
}

@font-face {
    font-family: S-Core-CoreSansA55Medium-Italic;
    src: url(../fonts-new/S-Core\ -\ CoreSansA55Medium-Italic.otf);
}

@font-face {
    font-family: S-Core-CoreSansA55Medium;
    src: url(../fonts-new/S-Core\ -\ CoreSansA55Medium.otf);
    font-style: normal;
}

@font-face {
    font-family: S-Core-CoreSansA65Bold-Italic;
    src: url(../fonts-new/S-Core\ -\ CoreSansA65Bold-Italic.otf);
}

@font-face {
    font-family: S-Core-CoreSansA65Bold;
    src: url(../fonts-new/S-Core\ -\ CoreSansA65Bold.otf);
}

@font-face {
    font-family: S-Core-CoreSansA75ExtraBold-Italic;
    src: url(../fonts-new/S-Core\ -\ CoreSansA75ExtraBold-Italic.otf);
}

@font-face {
    font-family: S-Core-CoreSansA75ExtraBold;
    src: url(../fonts-new/S-Core\ -\ CoreSansA75ExtraBold.otf);
}

@font-face {
    font-family: S-Core-CoreSansA85Heavy-Italic;
    src: url(../fonts-new/S-Core\ -\ CoreSansA85Heavy-Italic.otf);
}

@font-face {
    font-family: S-Core-CoreSansA85Heavy;
    src: url(../fonts-new/S-Core\ -\ CoreSansA85Heavy.otf);
}
@font-face {
  font-family: AvenirLTStd-Book;
  src: local('AvenirLTStd-Book'), url(../fonts-new/avenir_ff/AvenirLTStd-Book.otf) format('opentype');
}
@font-face {
  font-family: AvenirLTStd-Roman;
  src: local('AvenirLTStd-Roman'), url(../fonts-new/avenir_ff/AvenirLTStd-Roman.otf) format('opentype');
}