.main-header {
	color: #B13C2E;
	background: #FFF;
}

.header-inner {
	display: table;
	width:100%;
  /* max-width: 1100px; */
  /* max-width: 1200px; */
	margin: 0 auto; /* centering the element */
	padding: 0px 25px; 
	padding-left: 25px;
}

@media (max-width: 599.95px) {
    .header-inner {
        padding: 0px 25px; 
    }
}
.header-inner > * {
	display: table-cell;
	vertical-align: middle;
}

/* Align the menu */
.header-nav {
	text-align: left;
}
/*
   Put the menu in inline (inline-block, inline-table or inline-flex) to make it responsive to the text-align property
*/
.header-nav ul,
.header-nav li {
	display: inline;
	list-style: none;
}
.header-nav a {
	position: relative;
	display: inline-block;
	padding: 8px 25px 0 0;
	vertical-align: middle;
	font-weight: 300; /* between regular and light */
	letter-spacing: 0.025em;
	color: inherit;
    text-decoration: none;
    font-family: AvenirLTStd-Roman;
    letter-spacing: 0px;
    color: #223140;
    text-shadow: 0px 0px 0px #ffffff;
	opacity: 1;
	font-size: 24px;
}
/* Animating the link */
.header-nav a::after {
	content: "";
	position: absolute;
	bottom: 0; right: 20px; left: 20px;
	height: 2px;
	width: 40px;
	background-color: #0178EF;

	/* Preparing the transition */
	opacity: 0;
	transform: translateY(5px);
	transition: all .4s;
}

.header-nav .product-menu-section .product-link-lists::after {
	background-color: transparent;
}
.header-nav a.is-active::after {
	content: "";
	position: absolute;
	bottom: 0; right: 20px; left: 20px;
	height: 2px;
	width: 40px;
	background-color: #0178EF;

	/* Preparing the transition */
	opacity: 1;
	transform: translateY(5px);
	transition: all .4s;
}
.header-nav a:hover::after,
.header-nav a:focus::after {
	opacity: .6;
	transform: translateY(0);
}
/* I remove the outline effect because I already handle it */
.header-nav a:focus {
	outline: none;
}
.main-header {
	position: sticky;
  top: 0;
  z-index: 10;
}

.nav-is-stuck .main-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  animation: stickAnim .3s;
  opacity: 0.9;
}
 
@keyframes stickAnim {
	0% {
		transform: translateY(-86px);
	}
	100% {
		transform: translateY(0);
	}
}

.header-nav .is-active {
  color: #0178EF;
}

.product-menu-section {
  position: absolute;
  width: 100%;
  background: #FFF;
  box-shadow: 0 8px 6px -6px #cac7c7;
  left: 0;
  /* margin-top: 10px; */
}

.product-menu-section p {
  margin: 0;
}

.product-menu-section .product-link-lists {
  text-decoration: none;
  color: #223140;
}

.product-menu-section .product-link-lists:hover p img {
  width: 20px;
  transform: scale(1.5);
  transition: all .4s;
  margin-left: 10px;
}

.product-menu-section .product-link-lists p {
  line-height: 10px;
  /* font-size: 20px; */
  font-size: 1.2rem;
}

.product-menu-section .product-link-lists span {
  color: #666;
  font-size: 18px;
}

.product-menu-section .product-link-lists p img{
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

/* .product-menu-section .product-link-lists p img:hover {
  transform: scale(1.5);
} */

/* .display-product-menu:hover  + .product-menu-section {
  background: red;
  display: block;
} */

.main-header.large-screen.active-product-menu-open {
	padding-top: 15px;
}
.main-header.large-screen.active-product-menu-open .header-logo img {
	margin-top: -15px;
	height: 100px;
}