
.cookie-wrapper {
    position: fixed;
    z-index: 10;
    bottom: 30px;
    left: -370px;
    max-width: 345px;
    width: 100%;
    background: rgb(167, 193, 223);
    border-radius: 8px;
    padding: 15px 25px 15px;
    transition: left 0.3s ease;
}

.cookie-wrapper.show {
    left: 20px;
}

.cookie-wrapper header {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

header i {
    color: #314ea0;
    font-size: 25px;
}

header h2 {
    color: #314ea0;
    white-space: nowrap;
    font-size: 30px;
    font-weight: 400;
}

.cookie-wrapper .cookie-data {
    margin-top: 16px;
}

.cookie-wrapper .cookie-data p {
    color: #333;
    font-size: 16px;
}

.cookie-data p a {
    color: #4070f4;
    text-decoration: none;
}

.cookie-data p a:hover {
    text-decoration: underline;
}

.cookie-wrapper .cookie-buttons {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cookie-buttons .cookie-button {
    border: none;
    color: #fff;
    padding: 8px 0;
    border-radius: 4px;
    background: #4070f4;
    cursor: pointer;
    width: calc(100% / 2 - 15px);
    transition: all 0.2s ease;
}

.cookie-buttons .cookie-button:hover {
    background: #034bf1;
}



